/* Keyframes for slide-in animation */
@keyframes slideInFromBottom {
  0% {
    transform: translateY(20px); /* Start position: 20px down */
    opacity: 0; /* Start opacity: 0 */
  }
  100% {
    transform: translateY(0); /* End position: normal */
    opacity: 1; /* End opacity: 1 */
  }
}

/* Fade-in class to apply the animation */
.fade-in {
  animation: slideInFromBottom 1.5s ease forwards; /* Animation duration and easing */
}

/* Styles for the home-about-me section */
.home-about-me {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: #ff6347b3; /* Background color */
}

/* Styles for the h2 element */
.home-about-me h2 {
  color: #f2e2d0;
  font-size: 52px;
  letter-spacing: 2px;
  opacity: 0; /* Initial opacity */
}

/* Styles for the paragraph element */
.home-about-me p {
  margin: 0 auto;
  width: 60%;
  color: #0f436b;
  letter-spacing: 2px;
  text-align: center;
  opacity: 0; /* Initial opacity */
}

/* Styles for the button container */
.home-about-me .button {
  position: relative;
  z-index: 1;
  opacity: 0; /* Initial opacity */
}

/* Styles for button 56 */
.home-about-me .button-56 {
  background-color: #82a7c6;
}
.home-about-me .button-56:hover {
  background-color: #f2e2d0;
;
}
/* Media Query for Tablets (768px) */
@media (max-width: 768px) {
  .home-about-me h2 {
    font-size: 40px;
  }
  .home-about-me p {
    width: 80%;
    font-size: 16px;
  }
  .home-about-me {
    padding: 16px;
  }
  .home-about-me .button-56 {
    padding: 10px 20px;
  }
}

/* Media Query for Mobile (500px) */
@media (max-width: 500px) {
  .home-about-me h2 {
    font-size: 33px;
  }
  .home-about-me p {
    width: 90%;
    font-size: 12px;
    letter-spacing: 1px;
  }
  .home-about-me {
    padding: 12px;
  }
  .home-about-me .button-56 {
    padding: 8px 16px;
    font-size: 14px;
  }
}
