@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.cdnfonts.com/css/cyberjunkies');
/* Reset default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* fonts setting for the page */
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;

  line-height: 1.2;
}


