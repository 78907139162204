/* Hero section */
.hero {
  padding: 60px;
  width: 100%;
  background-image: url(../../../images/homeimages/background-hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative; /* Position relative to contain the overlay */
}

/* Overlay for the hero section */
.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0b323a80; /* Semi-transparent overlay */
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.title-job-me {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.text-of-me {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 27px;
}

.hero-content h1 {
  color: #f2e2d0;
  font-size: 60px;
}

.hero-content .hero-profession {
  font-style: italic;
  color: #f2e2d0;
  font-size: 24px;
}

.image-of-me {
  padding: 20px;
  padding-bottom: 0;
  background-color: rgba(130, 167, 198, 0.322); /* Subtle transparent background */
  border-radius: 24px;
  width: 30%;
  border-top: 4px solid #ffd700; /* Gold accent border */
  border-bottom: 4px solid #ffd700; /* Gold accent border */
  filter: brightness(1.2); 
}

/* Enhanced styling for the quote */
.hero-content .quote {
  margin: 0 auto;
  padding: 20px 30px;
  color: #f2e2d0;
  background-color: rgba(130, 167, 198, 0.322); /* Subtle transparent background */
  font-style: italic;
  font-size: 24px;
  letter-spacing: 1px;
  border-left: 4px solid #ebbd32; /* Gold accent border */
  border-radius: 8px; /* Slightly rounded corners */
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

/* Subtle hover effect on the quote */
.hero-content .quote:hover {
  border-left-color: #ffd700;
}

/* CTA Buttons */
.hero-cta-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
}
.hero-buttons-above-900{
  display: none;
}
/* Media Queries */

/* For laptops (1024px and below) */
@media (max-width: 1024px) {
  .hero{
    padding: 15px;
  }
  .hero-content h1 {
    font-size: 48px; /* Decrease the font size */
  }
  
  .hero-content .hero-profession {
    font-size: 20px; /* Decrease the font size */
  }
  .title-job-me{
    gap: 24px;
  }
  .text-of-me{
    gap: 24px;
  }
  
  .image-of-me {
    width: 40%; /* Increase width for better balance */
  }
}
@media (max-width: 900px) {
  .hero-buttons-bellow-900{
    display: none;
  }
  .hero-buttons-above-900{
    display: grid;

    grid-template-columns: 1fr 1fr ;
  }
  .hero-buttons-above-900 a:nth-child(3){
    grid-column: span 2;
  }
  .title-job-me{
    flex-direction: column;
    gap: 12px;

    padding: 0;
  }
  .text-of-me{
    padding: 20px;
    width: 100%;
  }
  .text-of-me .quote{
    padding: 12px 30px;
    width: 80%;
    text-align: center;
  }
}
/* For tablets (768px and below) */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 36px; /* Smaller font size */
  }
  
  .hero-content .hero-profession {
    font-size: 18px; /* Smaller font size */
  }
  .text-of-me .quote{
    font-size: 18px;
  }
  .image-of-me{
    width: 60%;
  }
}

/* For mobile (500px and below) */
@media (max-width: 560px) {
  .hero{
    padding: 0px 0px 20px 0px;
  }
  .hero-content h1 {
    font-size: 33px; /* Smaller font size for mobile */
  }
  .text-of-me .quote{
    margin: 0 auto;
    width: 100%;
    font-size: 15px;
  }
  .hero-content .hero-profession {
    font-size: 16px; /* Smaller font size */
  }
  
  .text-of-me {
    gap: 18px;
  }
  .image-of-me{
    width: 75%;
  }
  .hero-cta-buttons a{
    padding: 3px 6px;
    font-size: 14px;
  }
}