/* Keyframes for slide-in animation */
@keyframes slideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Fade-in class to apply the animation */
.fade-in {
  animation: slideIn 2s ease forwards;
}

/* Styles for the skills section */
.skills {
  width: 100%;
  padding: 40px 0px;
  background-color: #f2e2d0;
  color: #0f436b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}

.skills .title {
  font-size: 52px;
  text-align: center;
}

/* Skills paragraph with responsive width */
.skills-para {
  margin: 24px auto;
  width: 60%;
  color: #0f436b;
  letter-spacing: 1.5px;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
}

.skills .line {
  margin: 6px auto 18px auto;
  border: 2px dashed #0f436b;
  border-radius: 12px;
  width: 60%;
}

.slider-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Inner skill packs styling */
.inner-skill-pack {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.skill-title {
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}

/* Skill pack images */
.inner-skill-pack img {
  width: 128px;
  transition: transform 0.3s ease;
}

.inner-skill-pack img:hover {
  transform: scale(1.1);
}

/* Slick slider adjustments */
.slick-next {
  right: 0px;
}

/* Responsive for 1200px and below */
@media (max-width: 1200px) {
  .skills .title {
    font-size: 48px;
  }

  .skills-para {
    width: 70%;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .skill-title {
    font-size: 20px;
  }

  .inner-skill-pack img {
    width: 100px;
  }
}

/* Responsive for tablets (768px and below) */
@media (max-width: 768px) {
  .skills {
    padding: 30px 0px;
    gap: 12px;
  }

  .skills .title {
    font-size: 42px;
  }

  .skills-para {
    width: 80%;
    font-size: 16px;
  }

  .skill-title {
    font-size: 18px;
  }

  .inner-skill-pack img {
    width: 80px;
  }
}

/* Responsive for mobile (500px and below) */
@media (max-width: 500px) {
  .skills {
    padding: 20px 0px;
  }

  .skills .title {
    font-size: 33px;
  }

  .skills-para {
    width: 90%;
    font-size: 14px;
  }

  .skill-title {
    font-size: 16px;
  }

  .inner-skill-pack img {
    width: 60px;
  }
}
