/* Background Image for the Whole Page with Overlay */
.contact-us {
  position: relative;
  background-image: url('../../images/contactimages/background-image.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  min-height: 100vh;
  padding: 40px 20px;
}

.contact-us::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(11, 50, 58, 0.7); /* Semi-transparent dark overlay */
  z-index: -1;
}

/* Contact Section */
#contact-intro {
  text-align: center;
  margin-bottom: 40px;
  color: #fff; /* White text for better contrast */
  font-size: clamp(2rem, 4vw, 2.5rem); /* Responsive heading size */
}

#contact-intro p {
  max-width: 800px;
  margin: 0 auto;
  color: #f0f0f0;
  font-size: clamp(1.1rem, 2.5vw, 1.4rem); /* Responsive paragraph size */
  line-height: 1.6;
}

/* Form and Details Layout */
.form-and-details {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

/* Contact Form */
#contact-form {
  background-color: #ff6347; /* Tomato red background for form */
  border: none;
  padding: 40px;
  border-radius: 12px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #fff; /* White label for contrast */
}

input, select, textarea {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f2e2d0;
  color: #333;
  margin-top: 6px;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

#contact-form .button-styling {
  text-align: center;
}

#contact-form .button-56 {
  background-color: #f2e2d0;
  color: #333;
  padding: 12px 32px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#contact-form .button-56:hover {
  background-color: #4682b4; /* Steel blue on hover */
  color: #fff;
}

/* Contact Details */
.details-and-socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  max-width: 400px;
}

#contact-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

#contact-details li {
  margin-bottom: 15px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

#contact-details li i {
  margin-right: 10px;
  color: #3498db;
}

#contact-details a {
  color: #3498db;
  text-decoration: none;
}

#contact-details a:hover {
  text-decoration: underline;
}

/* Social Media Links */
#social-links {
  text-align: center;
  margin-top: 40px;
}

#social-links .social-icons {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

#social-links .social-icons li {
  display: inline-block;
}

#social-links .social-icons a {
  font-size: 2rem;
  color: #3498db;
  transition: color 0.3s ease;
}

#social-links .social-icons a:hover {
  color: #2980b9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-and-details {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  #contact-form {
    width: 100%;
    padding: 20px;
  }

  .details-and-socials {
    width: 100%;
    align-items: center;
    text-align: center;
  }

  #contact-intro {
    font-size: 1.8rem;
  }

  #contact-intro p {
    font-size: 1.1rem;
  }
}
