.skills-section {
  width: 100%;
  padding: 40px 20px;
  background-color: #f2e2d0;
  color: #0f436b;
  text-align: center;
}

.skills-section h2 {
  font-size: 36px;
  margin-bottom: 30px;
}

.skills-technologies-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px; /* Spacing between columns */
}

.skills-column,
.technologies-column {
  flex: 1; /* Equal width for both columns */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.column-title {
  font-size: 28px;
  margin-bottom: 20px;
}

.skill-item,
.tech-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.skill-item:hover,
.tech-item:hover {
  background-color: #f9f9f9;
}

.skill-title,
.tech-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skill-title h4,
.tech-title h4 {
  font-size: 22px;
  color: #0f436b;
  margin: 0;
  text-align: center;
}

.arrow {
  font-size: 24px;
  transition: transform 0.3s ease;
}

.arrow.rotate {
  transform: rotate(90deg);
}

.skill-description,
.tech-description {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease;
}

.skill-description.open,
.tech-description.open {
  max-height: 200px;
  padding-top: 10px;
}

.skill-description p,
.tech-description p {
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* Responsive Design */

@media (max-width: 1200px) {
  .skills-section h2 {
    font-size: 32px;
  }

  .skills-technologies-wrapper {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .skills-technologies-wrapper {
    flex-direction: column; /* Stack columns vertically */
  }

  .skills-column,
  .technologies-column {
    width: 100%; /* Full width on smaller screens */
  }
  
  .column-title {
    font-size: 24px;
  }
}

@media (max-width: 500px) {
  .skills-section h2 {
    font-size: 24px;
  }

  .skill-title h4,
  .tech-title h4 {
    font-size: 18px;
  }

  .skill-description p,
  .tech-description p {
    font-size: 14px;
  }
}
