.about-container {
  padding: 10px 20px;
  background-color: #f2e2d0;
  color: #0f436b;
  line-height: 1.6;
}

.about-container h1, .about-container h2 {
  text-align: center;
  font-size: 48px;
  margin-bottom: 20px;
}

.about-container p {
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.about-section {
  margin: 50px 0;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  text-align: center;
}

.my-jurney {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}
.my-jurney div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 21px;
}
.my-jurney div p {
  letter-spacing: 1px;
}
.values-section {
  display: flex;
  flex-direction: column; /* Row by default */
  justify-content: center;
  gap: 20px;
}

.values-grid {
  display: flex;
  justify-content: space-around;
  gap: 12px;
}

.skills-section .skill-card,
.values-section .value-card {
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center;
}

.skills-section .skill-card h3,
.values-section .value-card h3 {
  margin-bottom: 15px;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
}

.call-to-action {
  margin: 40px auto;
  text-align: center;
}

.call-to-action a {
  padding: 15px 30px;
  background-color: #0b323a;
  color: #f2e2d0;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.call-to-action a:hover {
  background-color: #0f436b;
}

/* Responsive Design */

/* For screens smaller than 1200px */
@media (max-width: 1200px) {
  .about-container h1, .about-container h2 {
    font-size: 40px;
  }

  .about-container p {
    font-size: 16px;
  }
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .values-grid {
    flex-direction: column; /* Change to column layout */
    align-items: center;
  }

  .about-container h1, .about-container h2 {
    font-size: 36px;
  }

  .about-container p {
    font-size: 16px;
  }

  .skills-section .skill-card, .values-section .value-card {
    width: 100%; /* Full width on smaller screens */
  }
}

/* For screens smaller than 500px */
@media (max-width: 500px) {
  .about-container h1, .about-container h2 {
    font-size: 28px;
  }

  .about-container p {
    font-size: 14px;
  }

  .skills-section .skill-card, .values-section .value-card {
    width: 100%;
  }

  .profile-image {
    width: 150px;
    height: 150px;
  }

  .call-to-action a {
    padding: 12px 24px;
    font-size: 16px;
  }
}
