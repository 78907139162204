/* Projects Section */
.projects-section {
  padding: 60px;
  background-color: rgb(130, 167, 198);
  text-align: center;
}

.projects-section h2 {
  font-size: 52px;
  color: #f2e2d0;
  margin-bottom: 2rem;
}

/* Project Card Styling */
.featured-projects {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.project-card {
  position: relative;
  max-width: 450px;
  height: 320px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 2s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.project-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.project-card:hover .project-image {
  transform: scale(1.25);
}

.project-image {
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease;
}

.project-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  padding: 30px;
  background-color: #062025d0;
  color: #f2e2d0;
  transition: bottom 0.6s ease;
  text-align: center;
  z-index: 1;
}

.project-card:hover .project-overlay {
  bottom: 0;
}

.project-overlay h3 {
  font-size: 18px;
  margin: 0 0 20px;
  color: #f2e2d0;
  letter-spacing: 2px;
}

.project-overlay p {
  font-size: 14px;
  margin-bottom: 20px;
}

.project-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

@media (max-width: 500px) {
  .projects-section{
    padding: 40px 15px;
  }
  .projects-section h2{
    font-size: 33px;
  }
  .projects-section .button-56{
    font-size: 14px;
  }
}