
/* CSS */
.button-56 {
  align-items: center;
  background-color: #ff6347;
  border: 2px solid #000;
  border-radius: 24px;
  color: #000;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  padding: 12px 24px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bold;
}

.button-56:after {
  background-color: #f2e2d0;
  border-radius: 24px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(6px, 6px);
  transition: transform .3s ease-in-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
  border-color: #000;
  color: #000;
  background-color: #f2e2d0;
}

@media (min-width: 768px) {
  
}